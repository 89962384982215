import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import '../index.css'
import {selectReminderContent } from "../../redux/store/fit/fitSelector";

const HPVKITSummary = () => {
    const hpvContent = useSelector(selectReminderContent);
    const bindText = (textSequenceId) => {
        let item = hpvContent?.find(
          (x) => x.textSequenceId === textSequenceId
        )?.text;
        return item;
      };
    return (
        <div className='container-fluid g-0'>
            <div className='card card-custom card-help'>
                <div className='card-header bg-inky text-white text-center'>
                    <img class=" w-15" src="../../../../Logo.svg" />
                </div>
                <div className='card-body card-body-h-vh bg-fitkit-card-sum p-0 container-340 '>
                    <div className='help-card-bottom help-card-2'>
                        <div className='card-thanks card-help-body'>
                            <p className="mb-0 lbl_medium lh-sm">
                                {/* Thank you - we will get that kit shipped out to you shortly. */}
                                {bindText(5)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HPVKITSummary