import React, { useRef, useState, useEffect } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlayIcon from "../../../assets/images/playIcon.png";
const Video = ({ videoData }) => {

  console.log("videoData", videoData[0]);
  const videoRefs = useRef([]);
  const [isPlaying, setIsPlaying] = useState({});
  const handlePlayPause = (item, ind) => {
    const videoElement = videoRefs?.current[ind];
    if (videoElement) {
      if (videoElement?.paused) {
        videoElement?.play();
        setIsPlaying({ ...isPlaying, [ind]: true });

        if (videoElement?.requestFullscreen) {
          videoElement?.requestFullscreen();
        } else if (videoElement?.mozRequestFullScreen) {
          videoElement?.mozRequestFullScreen();
        } else if (videoElement?.webkitRequestFullscreen) {
          videoElement?.webkitRequestFullscreen();
        } else if (videoElement?.msRequestFullscreen) {
          videoElement?.msRequestFullscreen();
        }
      } else {
        videoElement?.pause();
        setIsPlaying({ ...isPlaying, [ind]: true });
      }
    }
  };

  const handleVideoPause = (ind) => {
    setIsPlaying({ ...isPlaying, [ind]: false });
  };

  const convertToRGBA = (color, opacity) => {
    let rgba = "rgba(0,0,0,1)"; // Default black

    if (color.startsWith("#")) {
      // Convert HEX to RGB
      const hex = color.replace("#", "");
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      rgba = `rgba(${r},${g},${b},${opacity})`;
    } else if (color.startsWith("rgb")) {
      // Handle RGB format
      rgba = color.replace(/rgb(a?)\(([^)]+)\)/, (_, a, values) => {
        const rgbValues = values.split(",").slice(0, 3).map(v => v.trim());
        return `rgba(${rgbValues.join(",")},${opacity})`;
      });
    }

    return rgba;
  };

  const getImageStyles = (
    align,
    isFitToScreen,
    backgroundHeight,
    backgroundWidth,
    backgroundColor = "",
    backgroundOpacity = "",
    item,
    type,
    isWidth = true
  ) => {
    const marginTop = item?.marginTop || 0;
    const marginBottom = item?.marginBottom || 0;
    const marginLeft = item?.marginLeft || 0;
    const marginRight = item?.marginRight || 0;
    if(backgroundColor && backgroundOpacity) {
      backgroundColor = convertToRGBA(backgroundColor, backgroundOpacity);
    }

    let baseStyles = {
      // position: item?.align ? "absolute" : "relative",
      position: "relative",
      zIndex: 0,
      height: isFitToScreen
        ? "600px"
        : backgroundHeight && backgroundHeight !== "px"
          ? backgroundHeight
          : "300px",
      width: isFitToScreen
        ? "336px"
        : backgroundWidth && backgroundWidth !== "px"
          ? backgroundWidth
          : "150px",
      backgroundColor: type != "Button" && backgroundColor,
      marginTop: `${marginTop}px`,
      marginBottom: `${marginBottom}px`,
      marginLeft: `${marginLeft}px`,
      marginRight: `${marginRight}px`,
    };

    if (isWidth) {
      baseStyles = {
        // position: item?.align ? "absolute" : "relative",
        position: "relative",
        zIndex: 0,
        height: isFitToScreen
          ? "600px"
          : backgroundHeight && backgroundHeight !== "px"
            ? backgroundHeight
            : "300px",
        width: isFitToScreen
          ? "348px"
          : backgroundWidth && backgroundWidth !== "px"
            ? backgroundWidth
            : "150px",
        backgroundColor: type != "Button" && backgroundColor,
        marginTop: `${marginTop}px`,
        marginBottom: `${marginBottom}px`,
        marginLeft: `${marginLeft}px`,
        marginRight: `${marginRight}px`,
      };
    } else {
      baseStyles = {
        // position: item?.align ? "absolute" : "relative",
        position: "relative",
        marginTop: `${marginTop}px`,
        marginBottom: `${marginBottom}px`,
        marginLeft: `${marginLeft}px`,
        marginRight: `${marginRight}px`,
        backgroundColor: type != "Button" && backgroundColor,
      };
    }

    switch (align) {
      case "top left": {
        return {
          ...baseStyles,
          top: "0%",
          left: "0%",
          transform: "translate(0%, 0%)",
          textAlign: "left",
        };
      }
      case "top center": {
        return {
          ...baseStyles,
          top: "0%",
          left: "50%",
          transform: "translate(-50%, 0%)",
          textAlign: "center",
        };
      }
      case "top right": {
        return {
          ...baseStyles,
          top: "0%",
          right: "0%",
          transform: "translate(0%, 0%)",
          textAlign: "right",
        };
      }
      case "middle left": {
        return {
          ...baseStyles,
          top: "50%",
          left: "0%",
          transform: "translate(0%, -50%)",
          textAlign: "left",
        };
      }
      case "middle center": {
        return {
          ...baseStyles,
          textAlign: "center",
          top: item?.marginBottom
            ? `calc(50% - ${item?.marginBottom}px - 30px)`
            : `calc(50% - 30px)`,
          left: "50%",
          transform: "translate(-50%, -50%)",
        };
      }
      case "middle right": {
        return {
          ...baseStyles,
          top: "50%",
          right: "0%",
          transform: "translate(0%, -50%)",
          textAlign: "right",
        };
      }
      case "bottom left": {
        return {
          ...baseStyles,
          bottom: "0%",
          left: "0%",
          transform: "translate(0%, 0%)",
          textAlign: "left",
        };
      }
      case "bottom center": {
        return {
          ...baseStyles,
          bottom: "0%",
          left: "50%",
          transform: "translate(-50%, 0%)",
          textAlign: "center",
        };
      }
      case "bottom right": {
        return {
          ...baseStyles,
          bottom: "0%",
          right: "0%",
          transform: "translate(0%, 0%)",
          textAlign: "right",
        };
      }
      case "left": {
        return {
          ...baseStyles,
          top: "50%",
          left: "0%",
          transform: "translate(0%, -50%)",
          textAlign: "left",
        };
      }
      case "center": {
        return {
          ...baseStyles,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        };
      }
      case "right": {
        return {
          ...baseStyles,
          top: "50%",
          right: "0%",
          transform: "translate(0%, -50%)",
          textAlign: "right",
        };
      }
      default:
        return baseStyles;
    }

  };

  return (
    <>
      {videoData && videoData.length > 0 && videoData.map((item, ind) => {
        return (
          <div key={ind}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                border: item.videoUrl
                  ? `1px solid ${item?.borderColor
                    ? item?.borderColor
                    : "#000"
                  }`
                  : "",
                padding: "5px",
                borderRadius: item?.borderCorner,
                flexDirection:
                  item?.videoPosition === "left"
                    ? "row-reverse"
                    : "",
                ...getImageStyles(
                  item?.align,
                  item?.isFitToScreen,
                  item?.backgroundHeight,
                  item?.backgroundWidth,
                  item?.backgroundColor,
                  item?.backgroundOpacity,
                  item,
                  "Video",
                  false
                ),
              }}
            >
              <div style={{ width: "65%" }}>
                <div
                  style={{
                    marginTop: item?.headerMarginTop
                      ? `${item?.headerMarginTop}px`
                      : "",
                    marginLeft:
                      item?.headerMarginLeft
                        ? `${item?.headerMarginLeft}px`
                        : "",
                    marginRight:
                      item?.headerMarginRight
                        ? `${item?.headerMarginRight}px`
                        : "",
                    marginBottom:
                      item?.headerMarginBottom
                        ? `${item?.headerMarginBottom}px`
                        : "",
                  }}
                >
                  <p
                    className="mb-0 custom-font"
                    style={{
                      textOverflow: "ellipsis",
                      color: item?.headerTextColor,
                      overflow: "hidden",
                      textWrap: "nowrap",
                      paddingTop:
                        item?.headerPaddingTop
                          ? `${item?.headerPaddingTop}px`
                          : "",
                      paddingRight:
                        item?.headerPaddingRight
                          ? `${item?.headerPaddingRight}px`
                          : "",
                      paddingLeft:
                        item?.headerPaddingLeft
                          ? `${item?.headerPaddingLeft}px`
                          : "",
                      paddingBottom:
                        item?.headerPaddingBottom
                          ? `${item?.headerPaddingBottom}px`
                          : "",
                      fontSize:
                        item?.headerTextSize &&
                          item?.headerTextSize !==
                          "px"
                          ? item?.headerTextSize
                          : "1.2rem",
                      "--customeFont": item?.headerFont,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item?.videoTitle,
                    }}
                  ></p>
                </div>
                <div style={{}}>
                  <p
                    className="text_clamp custom-font"
                    dangerouslySetInnerHTML={{
                      __html: item?.videoDesc,
                    }}
                    style={{
                      color: item?.bodyTextColor,
                      fontSize:
                        item?.bodyTextSize &&
                          item?.bodyTextSize !== "px"
                          ? item?.bodyTextSize
                          : "10px",
                      lineHeight: "",
                      marginTop:
                        item?.bodyTextMarginTop
                          ? `${item?.bodyTextMarginTop}px`
                          : "",
                      marginLeft:
                        item?.bodyTextMarginLeft
                          ? `${item?.bodyTextMarginLeft}px`
                          : "",
                      marginRight:
                        item?.bodyTextMarginRight
                          ? `${item?.bodyTextMarginRight}px`
                          : "",
                      marginBottom:
                        item?.bodyTextMarginBottom
                          ? `${item?.bodyTextMarginBottom}px`
                          : "",
                      paddingTop:
                        item?.bodyTextPaddingTop
                          ? `${item?.bodyTextPaddingTop}px`
                          : "",
                      paddingRight:
                        item?.bodyTextPaddingRight
                          ? `${item?.bodyTextPaddingRight}px`
                          : "",
                      paddingLeft:
                        item?.bodyTextPaddingLeft
                          ? `${item?.bodyTextPaddingLeft}px`
                          : "",
                      paddingBottom:
                        item?.bodyTextPaddingBottom
                          ? `${item?.bodyTextPaddingBottom}px`
                          : "",
                      "--customeFont": item?.bodyTextFont,
                    }}
                  ></p>
                </div>
                {item?.iconPosition !== "right" &&
                  item?.newTag === "yes" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent:
                        "space-between",
                    }}
                  >
                    {item?.iconImage && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent:
                            item?.iconPosition !==
                              "left"
                              ? "flex-end"
                              : "space-between",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt="logo"
                          src={item?.iconImage}
                          style={{
                            height: "auto",
                            width: item?.iconSize
                              ? `${item?.iconSize}px`
                              : "2rem",
                            marginTop:
                              item?.iconMarginTop
                                ? `${item?.iconMarginTop}px`
                                : "",
                            marginLeft:
                              item?.iconMarginLeft
                                ? `${item?.iconMarginLeft}px`
                                : "",
                            marginRight:
                              item?.iconMarginRight
                                ? `${item?.iconMarginRight}px`
                                : "",
                            marginBottom:
                              item?.iconMarginBottom
                                ? `${item?.iconMarginBottom}px`
                                : "",
                          }}
                        />
                      </div>
                    )}
                    {item?.newTag === "yes" && (
                      <div
                        style={{
                          backgroundColor:
                            item?.newTagColor,
                          paddingLeft: 8,
                          paddingRight: 8,
                          borderRadius: 5,
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          marginRight:
                            item?.tagMarginRight
                              ? `${item?.tagMarginRight}px`
                              : "",
                          marginLeft:
                            item?.tagMarginLeft
                              ? `${item?.tagMarginLeft}px`
                              : "",
                          marginTop:
                            item?.tagMarginTop
                              ? `${item?.tagMarginTop}px`
                              : "",
                          marginBottom:
                            item?.tagMarginBottom
                              ? `${item?.tagMarginBottom}px`
                              : "",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            color: "#fff",
                            fontSize:
                              item?.newTagSize
                                ? `${item?.newTagSize}px`
                                : "0.8rem",
                          }}
                        >
                          New
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent:
                        "space-between",
                    }}
                  >
                    {item?.newTag === "yes" && (
                      <div
                        style={{
                          backgroundColor:
                            item?.newTagColor,
                          paddingLeft: 8,
                          paddingRight: 8,
                          borderRadius: 5,
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          marginRight:
                            item?.tagMarginRight
                              ? `${item?.tagMarginRight}px`
                              : "",
                          marginLeft:
                            item?.tagMarginLeft
                              ? `${item?.tagMarginLeft}px`
                              : "",
                          marginTop:
                            item?.tagMarginTop
                              ? `${item?.tagMarginTop}px`
                              : "",
                          marginBottom:
                            item?.tagMarginBottom
                              ? `${item?.tagMarginBottom}px`
                              : "",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            color: "#fff",
                            fontSize:
                              item?.newTagSize
                                ? `${item?.newTagSize}px`
                                : "0.8rem",
                          }}
                        >
                          New
                        </p>
                      </div>
                    )}
                    {item?.iconImage && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent:
                            item?.iconPosition !==
                              "left"
                              ? "flex-end"
                              : "space-between",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt="logo"
                          src={item?.iconImage}
                          style={{
                            height: "auto",
                            width: item?.iconSize
                              ? `${item?.iconSize}px`
                              : "2rem",
                            marginTop:
                              item?.iconMarginTop
                                ? `${item?.iconMarginTop}px`
                                : "",
                            marginLeft:
                              item?.iconMarginLeft
                                ? `${item?.iconMarginLeft}px`
                                : "",
                            marginRight:
                              item?.iconMarginRight
                                ? `${item?.iconMarginRight}px`
                                : "",
                            marginBottom:
                              item?.iconMarginBottom
                                ? `${item?.iconMarginBottom}px`
                                : "",
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              {item?.videoUrl && (
                <div
                  style={{
                    width: "45%",
                    height: "120px",
                    border: `1px solid #000`,
                    borderRadius: "5px",
                    position: "relative",
                    overflow: "hidden",
                    marginLeft: "5px",
                  }}
                >
                  <video
                    ref={(el) =>
                      (videoRefs.current[ind] = el)
                    }
                    onClick={() =>
                      handlePlayPause(item, ind)
                    }
                    onPause={() =>
                      handleVideoPause(ind)
                    }
                    poster={item?.thumbnailData}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    src={`${item?.videoUrl}`}
                  />

                  {!isPlaying[ind] && (
                    <img
                      alt="img"
                      onClick={() =>
                        handlePlayPause(item, ind)
                      }
                      src={PlayIcon}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform:
                          "translate(-50%, -50%)",
                        width: "50px",
                        height: "50px",
                        zIndex: 999,
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )
      })}
    </>
  );
};
export default Video;
