import FitImageBackground from "./backgrounds/FitImageBackground";
import ImageBackground from "./backgrounds/ImageBackground";
import RawBackground from "./backgrounds/RawBackground"; 
import ColorBackground from "./backgrounds/ColorBackground"; 
import FitColorBackground from "./backgrounds/FitColorBackground"; 
import FitVideoBackground from "./backgrounds/FitVideoBackground"; 
import VideoBackground from "./backgrounds/VideoBackground"; 

const Background = ({ children, previewData }) => {
  const renderComponent = () => { 
    if (previewData === undefined) {
      return (
        <RawBackground previewData={previewData}> {children}</RawBackground>
      );
    } 
    else if((previewData.imageData === undefined || previewData.imageData === "") && 
            (previewData.background === undefined || previewData.background==="" ) &&
            (previewData.videoData === undefined || previewData.videoData==="" ) )
            {
              return (
                <RawBackground previewData={previewData}> {children}</RawBackground>
              );
            }
    else if (previewData.imageData !== undefined && previewData.imageData !== "") {
      if (
        previewData.isFitToScreen === undefined ||
        previewData.isFitToScreen === false
      ) {
        return (
          <ImageBackground previewData={previewData}> {children}</ImageBackground>
        );
      } else {
        return (
          
          <FitImageBackground previewData={previewData}>
            {children}
          </FitImageBackground>
        );
      }
    }
    else if(previewData.background !== undefined && previewData.background !=="" )
    {
      if (
        previewData.isFitToScreen === undefined ||
        previewData.isFitToScreen === false
      ) {
        return (
          <ColorBackground previewData={previewData}> {children}</ColorBackground>
        );
      } else {
        return (
          <FitColorBackground previewData={previewData}>
            {children}
          </FitColorBackground>
        );
      }
    }
    else if(previewData.videoData !== undefined && previewData.videoData !=="" )
      {
        if (
          previewData.isFitToScreen === undefined ||
          previewData.isFitToScreen === false
        ) {
          return (
            <VideoBackground previewData={previewData}> {children}</VideoBackground>
          );
        } else {
          return (
            <FitVideoBackground previewData={previewData}> {children}</FitVideoBackground>
          );
        } 
      }
  };

  return renderComponent();
};

export default Background;
