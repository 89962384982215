import tinycolor from "tinycolor2";
import DOMPurify from "dompurify";
const Button = ({ previewData,onChangePage }) => {
  let alpha = previewData?.opacity ? previewData?.opacity : 1;
  const rgbaColor = previewData?.background
    ? tinycolor(previewData?.background).setAlpha(alpha).toRgbString()
    : "";

  const handleBtnClick = (navigateUrl) => {
    if(previewData.buttonType==="pageRedirect")
    {
      onChangePage(previewData.nextPage)
      return;
    }
    else
    {
      if (navigateUrl) {
        if (!/^https?:\/\//i.test(navigateUrl)) {
          navigateUrl = "https://" + navigateUrl;
        }
        window.open(navigateUrl, "_blank");
      }
    }

  };

  const getCommonPositionStyle = (item) => {
    let baseStyles = {
      position: item?.headerIconAlign ? "absolute" : "relative",
    };

    switch (item.headerIconAlign) {
      case "top left": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "top center": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "top right": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: 0,
          right: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`,
        };
      }
      case "middle left": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "middle center": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "middle right": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`,
        };
      }
      case "bottom left": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "bottom center": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "bottom right": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginRight: `${adjustedMarginRight}px`,
        };
      }
      default:
        return baseStyles;
    }
  };

  const getButtonStyle = (item) => {
    return {
      "--customeFont": previewData?.buttonFont,
      color: previewData?.buttonFontColor,
      borderRadius: previewData?.buttonRounding
        ? `${previewData?.buttonRounding}px`
        : "",
      border: `1px solid ${previewData?.borderColor}`,
      height: previewData?.ySize === "px" ? "auto" : `${previewData?.ySize}`,
      width: previewData?.xSize === "px" ? "auto" : `${previewData?.xSize}`,
      background:  rgbaColor ? rgbaColor : item?.background,
      marginTop: item?.marginTop ? `${item?.marginTop}px` : "0px",
      marginRight: item?.marginRight ? `${item?.marginRight}px` : "0px",
      marginBottom: item?.marginBottom ? `${item?.marginBottom}px` : "0px",
      marginLeft: item?.marginLeft ? `${item?.marginLeft}px` : "0px",
      paddingTop: item?.paddingTop ? `${item?.paddingTop}px` : "0px",
      paddingRight: item?.paddingRight ? `${item?.paddingRight}px` : "0px",
      paddingBottom: item?.paddingBottom ? `${item?.paddingBottom}px` : "0px",
      paddingLeft: item?.paddingLeft ? `${item?.paddingLeft}px` : "0px",
    };
  };

  let btnTxtAlign = previewData?.align || "left";
  if (previewData?.align) {
    let alignArr = previewData?.align?.split(" ");
    btnTxtAlign = alignArr.length > 0 && alignArr[1];
  }
  return (
    <div
      style={{
        textAlign: btnTxtAlign || "left",
        ...getCommonPositionStyle(previewData),
      }}
    >
      <button
        className="custom-font"
        onClick={() => handleBtnClick(previewData?.buttonLink)}
        style={{ ...getButtonStyle(previewData) }}
      >
        {previewData?.text ? (
          <div
          style={{"--customeFont": previewData?.buttonFont}}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(previewData.text),
            }}
            className="editor-inner-text custom-font"
          />
        ) : (
          "Button"
        )}
      </button>
    </div>
  );
};
export default Button;
