const RawBackground = ({ children, previewData }) => {
    return (
      <div
        style={{
          "overflow-y": "scroll",
          "scrollbar-width": "none",
          "-ms-overflow-style": "none",
          position: "absolute",
          height: "100%",
          width: "100%",
          "border-radius": "0 0 31px 31px",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className="backgroundHolder"
      >
        {children}
      </div>
    );
  };

  export default RawBackground