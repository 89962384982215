import { takeLatest, put, call, all } from "redux-saga/effects";
import axios from "axios";
import { setError, setLoader, setTokenError } from "../loader/loaderAction";
import { setTemplateMetaData, setUserCampaignData } from "./templateActions";

export function* getTemplateResponse(data) {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const templateResponse = yield axios.get(  `${process.env.REACT_APP_ENLIGHTEN_API_URL}/api/CampaignTemplatePreview/GetTemplateByCampaignCode?campaignId=${data.payload.campCode}&recordId=${data.payload.id}`, config);
    // const templateResponse = yield axios.get(  `${process.env.REACT_APP_ENLIGHTEN_API_URL_TESTING}/api/TemplatePreview/GetTemplateByCampaignCode?campaignCode=${data.payload.campCode}&userGuid=${data.payload.id}`, config);

    yield put(setTemplateMetaData(templateResponse.data.value));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid."));
    } else {
      yield put(setError("An error occurred please contact admin"));
    }
    yield put(setLoader(false));
    console.log("error", error);
  }
}

export function* onGetTemplateData() {
  yield takeLatest("GET_TEMPLATE_META_DATA", getTemplateResponse);
}

export function* getCampaignResponse(data) {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const campaignResponse = yield axios.get(  `${process.env.REACT_APP_ENLIGHTEN_API_URL}/api/CampaignTemplatePreview/GetResponse?CampaignId=${data.payload.campCode}&recordId=${data.payload.data}`,
 config
    );
//     const campaignResponse = yield axios.get(  `${process.env.REACT_APP_ENLIGHTEN_API_URL_TESTING}/api/ScheduleAppointment/GetUserCampaign?campaignCode=${data.payload.campCode}&userGuid=${data.payload.data}`,
//  config
//     );
    yield put(setUserCampaignData(campaignResponse.data.value));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid."));
    } else {
      yield put(setError("An error occurred please contact admin"));
    }
    yield put(setLoader(false));
    console.log("error", error);
  }
}

export function* onGetCampaignData() {
  yield takeLatest("GET_USER_CAMPAIGN_DATA", getCampaignResponse);
}

export function* templateSagas() {
  yield all([call(onGetTemplateData), call(onGetCampaignData)]);
}
