import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    setError,
    setLoader,
    setTokenError,
  } from "../../../redux/store/loader/loaderAction";
import ErrorModal from './errorModal'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';
import Calendar from '../../components/Calendar';
import '../ColoSummary.css'
import axios from 'axios'

const ValidateDob = () => {
    const [dob, setDob] = useState()
    //const { userValue } = useContext(tokenContext);
    const { userValue } = {};
    const [open, setOpen] = useState()
    const [date, setDate] = useState(null)
    const [calendarOpen, setCalendarOpen] = useState(false)
    const navigate = useNavigate()
    const [endDate, setEndDate] = useState(null)
    const [show, setShow] = useState(false)
    const [minDate, setMinDate] = useState(null);
    const [apptSelected, setApptSelected] = useState(false);
  const [apptType, setApptType] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    dispatch(setError(""));
    if (!token) {
      dispatch(setError("token is missing. Please contact Admin"));
      dispatch(setLoader(false));
      return;
    } 
  }, []);
  

    const handleSubmit = async () => {
        const config = {
            headers: {
              "Authorization": `Bearer ${token}`,
            },
          };
          try {
            dispatch(setLoader(true));
            let validationResult = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/component/validateUserDob`, 
                { dob: moment(date).format('MM/DD/yyyy') }, config);
            dispatch(setLoader(false));
            if(validationResult.data.validationResult == "Fail"){
                dispatch(setError("The provided information does not match our records. Please verify and try again."));
            }    
          } catch (err) {
            dispatch(setError("The provided information does not match our records. Please verify and try again."));
            console.log(err);
            dispatch(setLoader(false));
          }
    }

    const onClose = () => {
        setOpen(false);
    }

    const handleChangeTime = (e) => {
    }

    return (<>
        <div className='container-fluid g-0'>
            <div className='card card-custom card-help'>
                <div className='card-header bg-blue text-white text-center'>
                    <img class=" w-15" src="../../../Logo.svg" />
                </div>
                <div className="container-fluid">
                    <div className="steps-page-heading mb-4">
                        <h6 className="p_header_text text-inky-blue text-start mt-4 fs-6">Before we start, we need to make sure that it’s you, 
                                            please let us know your date of birth</h6>
                    </div>
                    <div className='d-flex flex-column align-items-center mt-5' >
                        <div className=" mb-2 calender-picker">
                            {<Calendar show={show} setShow={setShow} date={date} endDate={endDate} setDate={setDate} minDate={minDate} />}
                            {<input type="text" id="datepicker" value={date ? moment(date).format('MM/DD/yyyy') : null} className="form-control form-control-datepicker min-250-width" onClick={(() => setShow(true))} readOnly />}
                            {<label for="datepicker" className="calendar-dropdown"></label>}
                        </div>

                        {<>
                            <div className="mt-4 text-center m-auto steps-group completed">
                                <button className={"btn min-150-width btn-outline-primary btn btn-outline-primary-steps " + (date == null || date == undefined ? ' disabled' : ' ')} 
                                disabled={date == null || date == undefined} 
                                onClick={() => date && handleSubmit('Video')}>Submit</button>
                            </div></>}
                    </div>
                    {open && <ErrorModal message={"The provided information does not match our records. Please verify and try again."} isOpen={true} onClose={onClose} />}
                </div>
            </div>
        </div>
    </>
    )
}

export default ValidateDob