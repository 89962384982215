const FitColorBackground = ({ children, previewData }) => {
  return (
    <div
      style={{
        "overflow-y": "scroll",
        "scrollbar-width": "none",
        "-ms-overflow-style": "none",
        position: "absolute",
        height: "100%",
        width: "100%",
        "border-radius": "0 0 31px 31px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
         background: `${previewData.background}`,
      }}
    >
      {children}
    </div>
  );
};

export default FitColorBackground;
