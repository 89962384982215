import React, { useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import tinycolor from "tinycolor2";
import DOMPurify from "dompurify";

const Map = ({ previewData }) => {
  const [userLocation, setUserLocation] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const hasLocations = previewData?.locationSetting === "location list";

  // Function to calculate the distance between two coordinates (Haversine formula)
  const getDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Earth's radius in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  // Function to find the nearest location
  const findNearestLocation = (userLat, userLng, locations) => {
    return locations.reduce((nearest, location) => {
      const distance = getDistance(
        userLat,
        userLng,
        Number(location.lat),
        Number(location.long)
      );
      return !nearest || distance < nearest.distance
        ? { ...location, distance }
        : nearest;
    }, null);
  };

  useEffect(() => {
    if (hasLocations && navigator.geolocation && window.isSecureContext) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          setUserLocation(null); // User denied or error
        }
      );
    }
  }, [hasLocations]);

  useEffect(() => {
    if (userLocation) {
      if (previewData?.location?.locationInfos?.length) {
        const nearest = findNearestLocation(
          userLocation.lat,
          userLocation.lng,
          previewData.location.locationInfos
        );
        setSelectedLocation(nearest);
      } else {
        setSelectedLocation(null);
      }
    } else {
      if (previewData?.location?.locationInfos?.length) {
        setSelectedLocation(previewData.location.locationInfos[0]);
      } else {
        setSelectedLocation(null);
      }
    }
  }, [previewData?.location]);


  // Determine the best center
  let center = { lat: 0, lng: 0 };

  if (hasLocations && previewData.location?.locationInfos?.length > 0) {
    if (userLocation) {
      const nearest = findNearestLocation(
        userLocation.lat,
        userLocation.lng,
        previewData.location.locationInfos
      );
      center = nearest
        ? { lat: Number(nearest.lat), lng: Number(nearest.long) }
        : center;
    } else {
      center = {
        lat: Number(previewData.location.locationInfos[0]?.lat) || 0,
        lng: Number(previewData.location.locationInfos[0]?.long) || 0,
      };
    }
  } else {
    center = {
      lat: Number(previewData?.xCoordinatePosition) || 0,
      lng: Number(previewData?.yCoordinatePosition) || 0,
    };
  }

  return (
    <div
      style={{
        paddingTop: previewData?.mapPaddingTop
          ? `${previewData?.mapPaddingTop}px`
          : 0,
        paddingRight: previewData?.mapPaddingRight
          ? `${previewData?.mapPaddingRight}px`
          : 0,
        paddingBottom: previewData?.mapPaddingBottom
          ? `${previewData?.mapPaddingBottom}px`
          : 0,
        paddingLeft: previewData?.mapPaddingLeft
          ? `${previewData?.mapPaddingLeft}px`
          : 0,
      }}
    >
      <GoogleMap
        zoom={13}
        mapContainerStyle={{
          height: "250px",
          width: "100%",
          borderRadius: previewData?.borderRadius
            ? `${previewData?.borderRadius}px`
            : "10px",
          border: `1px solid ${previewData?.borderColor}`,
        }}
        center={center}
      >
        {hasLocations &&
          previewData?.location?.locationInfos?.map((location) => (
            <Marker
              onClick={() => {
                setSelectedLocation(location);
              }}
              key={`${location.id}`}
              position={{
                lat: Number(location.lat),
                lng: Number(location.long),
              }}
              title={location.facilityName}
            />
          ))}

        {userLocation && (
          <Marker
            position={userLocation}
            title="Your Location"
            icon={{
              url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
            }}
          />
        )}
      </GoogleMap>

      <div
        className="d-flex mt-2 justify-content-between"
        style={{
          backgroundColor: previewData?.locationInfoBackgroundColor
            ? tinycolor(previewData?.locationInfoBackgroundColor)
              .setAlpha(previewData?.locationInfoBackgroundTransparency ?? 1)
              .toRgbString()
            : undefined,
          ...(previewData?.locationInfoBorderRadius && {
            borderRadius: `${previewData?.locationInfoBorderRadius}px`,
          }),
        }}
      >
        {hasLocations ? (
          <LocationInfo
            selectedLocation={selectedLocation}
            previewData={previewData}
          />
        ) : (
          <ManualLocationInfo previewData={previewData} />
        )}
      </div>
    </div>
  );
};

const LocationInfo = ({ previewData, selectedLocation }) => {
  const [address, setAddress] = useState("");
  useEffect(() => {
    if (selectedLocation != null) {
      let fullAddress = `${selectedLocation?.streetOne}, ${selectedLocation?.streetTwo || ""
        } ${selectedLocation?.city} ${selectedLocation?.state} ${selectedLocation?.zipCode
        }`;
      setAddress(fullAddress);
    }
  }, [selectedLocation]);
  return (
    <>
      {selectedLocation !== null && (
        <>
          <div
            className="editor-inner-text custom-font"
            style={{
              width: "45%",
              "--customeFont": previewData?.locationTextFont,
              fontSize: previewData?.locationTextSize,
              color: previewData?.locationTextColor,
              marginTop: previewData?.textMarginTop
                ? `${previewData?.textMarginTop}px`
                : "0px",
              marginRight: previewData?.textMarginRight
                ? `${previewData?.textMarginRight}px`
                : "0px",
              marginBottom: previewData?.textMarginBottom
                ? `${previewData?.textMarginBottom}px`
                : "0px",
              marginLeft: previewData?.textMarginLeft
                ? `${previewData?.textMarginLeft}px`
                : "0px",
              paddingTop: previewData?.textPaddingTop
                ? `${previewData?.textPaddingTop}px`
                : "0px",
              paddingRight: previewData?.textPaddingRight
                ? `${previewData?.textPaddingRight}px`
                : "0px",
              paddingBottom: previewData?.textPaddingBottom
                ? `${previewData?.textPaddingBottom}px`
                : "0px",
              paddingLeft: previewData?.textPaddingLeft
                ? `${previewData?.textPaddingLeft}px`
                : "0px",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              Address
            </p>
            <p>{address}</p>
          </div>
          <div
            className="editor-inner-text custom-font"
            style={{
              width: "45%",
              "--customeFont": previewData?.locationHourTextFont,
              fontSize: previewData?.locationHourTextSize,
              color: previewData?.locationHourTextColor,
              marginTop: previewData?.locationHoursMarginTop
                ? `${previewData?.locationHoursMarginTop}px`
                : "0px",
              marginRight: previewData?.locationHoursMarginRight
                ? `${previewData?.locationHoursMarginRight}px`
                : "0px",
              marginBottom: previewData?.locationHoursMarginBottom
                ? `${previewData?.locationHoursMarginBottom}px`
                : "0px",
              marginLeft: previewData?.locationHoursMarginLeft
                ? `${previewData?.locationHoursMarginLeft}px`
                : "0px",
              paddingTop: previewData?.locationHoursPaddingTop
                ? `${previewData?.locationHoursPaddingTop}px`
                : "0px",
              paddingRight: previewData?.locationHoursPaddingRight
                ? `${previewData?.locationHoursPaddingRight}px`
                : "0px",
              paddingBottom: previewData?.locationHoursPaddingBottom
                ? `${previewData?.locationHoursPaddingBottom}px`
                : "0px",
              paddingLeft: previewData?.locationHoursPaddingLeft
                ? `${previewData?.locationHoursPaddingLeft}px`
                : "0px",
            }}
          >
            <p style={{ fontWeight: "bold" }}>Hours</p>
            <p>
              {"Mon - Fri : " +
                selectedLocation.locationWeekdayOpen +
                " - " +
                selectedLocation.locationWeekdayOpen}
            </p>
            {selectedLocation.locationSaturdayOpen &&
              selectedLocation.locationSaturdayClose && (
                <p>
                  {"Saturday : " +
                    selectedLocation.locationSaturdayOpen +
                    " - " +
                    selectedLocation.locationSaturdayClose}
                </p>
              )}
            {selectedLocation.locationSundayOpen &&
              selectedLocation.locationSundayClose && (
                <p>
                  {"Sunday : " +
                    selectedLocation.locationSundayOpen +
                    " - " +
                    selectedLocation.locationSundayClose}
                </p>
              )}

            {selectedLocation.locationWeekdayLunchStart &&
              selectedLocation.locationWeekdayLunchEnd && (
                <p>
                  {"Lunch time : " +
                    selectedLocation.locationWeekdayLunchStart +
                    " - " +
                    selectedLocation.locationWeekdayLunchEnd}
                </p>
              )}
            <p>{selectedLocation.phoneNumber}</p>
          </div>
        </>
      )}
    </>
  );
};
const ManualLocationInfo = ({ previewData }) => {
  return (
    <>
      <div
        className="editor-inner-text custom-font"
        style={{
          width: "45%",
          "--customeFont": previewData?.locationTextFont,
          marginTop: previewData?.textMarginTop
            ? `${previewData?.textMarginTop}px`
            : "0px",
          marginRight: previewData?.textMarginRight
            ? `${previewData?.textMarginRight}px`
            : "0px",
          marginBottom: previewData?.textMarginBottom
            ? `${previewData?.textMarginBottom}px`
            : "0px",
          marginLeft: previewData?.textMarginLeft
            ? `${previewData?.textMarginLeft}px`
            : "0px",
          paddingTop: previewData?.textPaddingTop
            ? `${previewData?.textPaddingTop}px`
            : "0px",
          paddingRight: previewData?.textPaddingRight
            ? `${previewData?.textPaddingRight}px`
            : "0px",
          paddingBottom: previewData?.textPaddingBottom
            ? `${previewData?.textPaddingBottom}px`
            : "0px",
          paddingLeft: previewData?.textPaddingLeft
            ? `${previewData?.textPaddingLeft}px`
            : "0px",
        }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(previewData.locationText),
        }}
      />

      <div
        className="editor-inner-text custom-font"
        style={{
          width: "45%",
          "--customeFont": previewData?.locationHourTextFont,
          marginTop: previewData?.locationHoursMarginTop
            ? `${previewData?.locationHoursMarginTop}px`
            : "0px",
          marginRight: previewData?.locationHoursMarginRight
            ? `${previewData?.locationHoursMarginRight}px`
            : "0px",
          marginBottom: previewData?.locationHoursMarginBottom
            ? `${previewData?.locationHoursMarginBottom}px`
            : "0px",
          marginLeft: previewData?.locationHoursMarginLeft
            ? `${previewData?.locationHoursMarginLeft}px`
            : "0px",
          paddingTop: previewData?.locationHoursPaddingTop
            ? `${previewData?.locationHoursPaddingTop}px`
            : "0px",
          paddingRight: previewData?.locationHoursPaddingRight
            ? `${previewData?.locationHoursPaddingRight}px`
            : "0px",
          paddingBottom: previewData?.locationHoursPaddingBottom
            ? `${previewData?.locationHoursPaddingBottom}px`
            : "0px",
          paddingLeft: previewData?.locationHoursPaddingLeft
            ? `${previewData?.locationHoursPaddingLeft}px`
            : "0px",
        }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(previewData.locationHoursText),
        }}
      />
    </>
  );
};

export default Map;
