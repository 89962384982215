import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "./TemplateView/Header";
import Map from "./TemplateView/Map";
import Background from "./TemplateView/Background";
import Video from "./TemplateView/Video";
import Button from "./TemplateView/Button";
import Text from "./TemplateView/Text";
import Navigation from "./TemplateView/Navigation";
import Appointment from "./TemplateView/Appointment";
import { decodeToken } from "../../utils";
import { getTemplateMetaData, getUserCampaignData } from "../../redux/store/template/templateActions";
import axios from "axios";
import '../components/TemplateComponents/helpers/style.css'
import { setLoader } from "../../redux/store/loader/loaderAction";

const Container = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [bgData, setBgData] = useState({});
  const [navData, setNavData] = useState([]);

  const [headerHeight, setHeaderHeight] = useState(0);
  const [responses, setResponses] = useState([]);
  const [checkboxState, setCheckboxState] = useState({});
  const [selectedButton, setSelectedButton] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  localStorage.setItem("token", token);
  const dataDecode = decodeToken(token);
  const tmData = useSelector((state) => state.templateReducer.tmData);
  const getUserCampData = useSelector((state) => state.templateReducer.getUserCampData);

  useEffect(() => {
    if (tmData && tmData.metaData) {
      const parsedData = JSON.parse(tmData.metaData);
      setPreviewData(parsedData)
      setPageData(parsedData[`page${currentPage}`] || []);
    }
  }, [tmData, currentPage]);

  useEffect(() => {
    if (token) {
      const data = decodeToken(token);
      let userGuid = dataDecode.UserId;
      let campaignCode = dataDecode.campCode;
      if (data) {
        dispatch(getTemplateMetaData({ id: data["UserId"], campCode: data["campCode"] }));
        dispatch(getUserCampaignData(campaignCode, userGuid));
      }
    }
  }, [dataDecode.UserId, dataDecode.campCode, dispatch, token]);

  useEffect(() => {
    let bgItem = pageData?.find((item) => item?.type === "Background");
    let navItem = pageData?.find((item) => item?.type === "Navigation");
    let bgValue = bgItem?.values?.find((item) => item?.imageData);
    let navValue = navItem?.values?.find((item) => item);
    setBgData(bgValue ? bgValue : {});
    setNavData(navValue ? navValue : {});
  }, [pageData]);

  useEffect(() => {
    let pageControlHeader = document.getElementById("pageControlHeader");
    setHeaderHeight(pageControlHeader?.clientHeight)
  }, [pageData])

  const handleSave = (actionButtonData, type, isGetData = true) => {
    const stateToUse = checkboxState;
    let responseDesc = [];
    const duplicateData = (array, newItem) => {
      return array.some((item) => item.id === newItem.id && item.parentId === newItem.parentId);
    };

    const addUniqueResponses = (sourceArray) => {
      sourceArray.forEach((item) => {
        if (item.result !== null && item.question !== null && !duplicateData(responseDesc, item)) {
          responseDesc.push(item);
        }
      });
    };

    const extractResponsesFromState = (state) => {
      for (const parentId in state) {
        for (const questionId in state[parentId]) {
          const { question, result } = state[parentId][questionId];
          if (result !== null && question !== null) {
            responseDesc.push({ parentId, id: questionId, question, result });
          }
        }
      }
    };

    const filterAndAddActionData = (actionData, type) => {
      if (actionData) {
        if (type === "Checklist") {
          responseDesc = responseDesc.filter((item) => item.id !== actionData.id);
        } else {
          responseDesc = responseDesc.filter((item) => item.parentId !== actionData.parentId);
        }
        responseDesc.push(actionData);
      }
    };

    extractResponsesFromState(stateToUse);
    addUniqueResponses(responses);

    if (getUserCampData && getUserCampData.responseDesc) {
      addUniqueResponses(getUserCampData.responseDesc);
    }

    filterAndAddActionData(actionButtonData, type);

    const payload = {
      userGuid: dataDecode.UserId,
      campaignCode: dataDecode.campCode,
      responseDesc,
    };

    saveAppointment(payload, isGetData);
  };

  const saveAppointment = async (payload, isGetData) => {
    let bearerToken = localStorage.getItem("token");
    const config = { headers: { Authorization: `Bearer ${bearerToken}` } };
    let userGuid = dataDecode.UserId;
    let campaignCode = dataDecode.campCode;
    const data = decodeToken(token);
    // if(isGetData){
    try {
      dispatch(setLoader(true))
      const res = await axios.post(`${process.env.REACT_APP_ENLIGHTEN_API_URL}/api/CampaignTemplatePreview/SaveCampaignResponse`,
        {
          recordId: payload.userGuid,
          campaignId: payload.campCode,
          data: payload.responseDesc
        }, config);
      if (res.status === 200) {
        if (isGetData) {
          dispatch(getTemplateMetaData({ id: data["UserId"], campCode: data["campCode"] }));
        }
        dispatch(getUserCampaignData(campaignCode, userGuid));
        dispatch(setLoader(false))
      }
    } catch (error) {
      dispatch(setLoader(false))
      console.error("Error booking appointment:", error);
    }
    // }
  };

  const handleSelectNextPrevPage = (page, data) => {
    let findObj = data && data.length > 0 && data.find((elem) => elem.type === "Page");
    let nextPageNo = findObj?.values[0]?.nextPage;
    let prevPageNo = findObj?.values[0]?.previousPage;

    const tempPreviewData = { ...previewData };
    let currentPageNum = Number(tempPreviewData.currentPage?.replace("page", ""));
    if (page === "prev") {
      currentPageNum = prevPageNo ? prevPageNo : currentPageNum - 1;
    } else {
      currentPageNum = nextPageNo ? nextPageNo : currentPageNum + 1;
    }
    const newPage = `page${currentPageNum}`;

    if (tempPreviewData[newPage]) {
      setPreviewData({ ...previewData, currentPage: newPage });
      setPageData(tempPreviewData[newPage] || []);
    }
  };

  const handleBtnNavigate = (page) => {
    const tempPreviewData = { ...previewData };
    const newPage = `page${page}`;
    if (tempPreviewData[newPage]) {
      setPreviewData({ ...previewData, currentPage: newPage });
      setPageData(tempPreviewData[newPage] || []);
    }
  };


  // const renderComponent = (item) => {
  //   switch (item.type) {
  //     case "Action Header":
  //       return <ActionHeader key={item.id} data={item} />;
  //     case "Action Button":
  //       return <ActionButton key={item.id} data={item} handleSave={handleSave} selectedButton={selectedButton} setSelectedButton={setSelectedButton} />;
  //     case "BulletText":
  //       return <BulletText key={item.id} data={item} />;
  //     case "Button":
  //       return <ButtonForm key={item.id} data={item} previewData={previewData} handleSave={handleSave} handleBtnNavigate={handleBtnNavigate} />;
  //     case "Cancel Button":
  //       return <CancelButton key={item.id} data={item} />;
  //     case "Checklist":
  //       return <CheckList key={item.id} data={item} checkboxState={checkboxState} setCheckboxState={setCheckboxState} handleSave={handleSave} />;
  //     case "Colo Header":
  //       return <ColoHeader key={item.id} data={item} />;
  //     case "Duel Graphic":
  //       return <DuelGraphic key={item.id} data={item} />;
  //     case "Header":
  //       return <Header key={item.id} data={item.values} />;
  //     case "Navigation":
  //       return <Navigation key={item.id} previewData={previewData} handleSelectNextPrevPage={handleSelectNextPrevPage} setPreviewData={setPreviewData} pageData={pageData} setPageData={setPageData} data={item} currentPage={currentPage} />;
  //     case "Horizontal Line":
  //       return <HorizontalLine key={item.id} data={item} />;
  //     case "Icon Action":
  //       return <IconAction key={item.id} data={item} />;
  //     case "Intro":
  //       return <Intro key={item.id} data={item} />;
  //     case "Map":
  //       return <MapComponent key={item.id} data={item} handleSave={handleSave} />;
  //     case "Question":
  //       return <Question key={item.id} data={item} />;
  //     case "Single Graphic":
  //       return <SingleGraphic key={item.id} data={item} />;
  //     case "Sub Header":
  //       return <SubHeader key={item.id} data={item} />;
  //     case "Text":
  //       return <Text key={item.id} data={item} />;
  //     case "Text Graphic":
  //       return <TextGraphic key={item.id} data={item} />;
  //     case "Text Box":
  //       return <TextBox key={item.id} data={item} responses={responses} setResponses={setResponses} saveAppointment={saveAppointment} handleSave={handleSave} />;
  //     case "Video":
  //       return <Video key={item.id} data={item} handleSave={handleSave} getUserCampData={getUserCampData} />;
  //     default:
  //       return null;
  //   }
  // };

  const changePage = (pageNumber) => {
    setPreviewData((prevData) => ({
      ...prevData,
      currentPage: `page${pageNumber}`,
    }));
  };

  const renderComponent = (type, values) => {
    switch (type) {
      case "Header":
        return <Header previewData={values[0]} />;
      case "Map":
        return <Map previewData={values[0]} />;
      case "Video":
        return <Video videoData={values} />;
      case "Button":
        return <Button onChangePage={(pageNumber)=>{changePage(pageNumber)}} previewData={values[0]} />;
      case "Text":
        return <Text previewData={values[0]} />;
      case "Appointment":
        return <Appointment previewData={values[0]} />;
      case "Navigation":
        return <Navigation previewData={previewData} setPreviewData={setPreviewData}/>;
      default:
        return null;
    }
  };

  const backgroundPreviewData = previewData[previewData.currentPage]?.find(
    (x) => x.type === "Background"
  );

  return (
    <div className="container-fluid g-0">
      <div className="card card-custom card-help"
      >
        <div
          className="card-body py-0 pb-0 card-body-h-vh p-0 container-340 d-flex flex-column justify-content-between"
        >
          <div className="position-relative flex-grow-1"
            // style={{minHeight: `calc(100svh - ${headerHeight}px)`, top: "-16px"}}
            // style={{minHeight: "calc(100svh - 98px)", top: "-16px"}}
            style={{ minHeight: "calc(100svh)", top: "0" }}
          >
            <Background previewData={backgroundPreviewData?.values[0]}>
              <div>
                {previewData[previewData.currentPage]?.map(
                  ({ id, type, values }, index) => (
                    <React.Fragment key={id}>{renderComponent(type, values)}</React.Fragment>
                  )
                )}
              </div>
            </Background>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Container;
