import React, { useState, useRef, useEffect } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

const VideoBackground = ({ children, previewData }) => {
  const [hoveredButton, setHoveredButton] = useState(null);
  const [muted, setMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true); // Default state: video is playing
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      const isMute = (previewData?.muteOnOpen === undefined) ? false : previewData?.muteOnOpen;
      setMuted(isMute);
    }
  }, [previewData.muteOnOpen]);


  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying); // Toggle the play/pause state
    }
  };

  const handleReset = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
      videoRef.current.play();
      setIsPlaying(true); // Ensure play state is true after reset
    }
  };

  const handleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !muted;
      setMuted(!muted);
    }
  };

  const handleVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Reset the video to the beginning
      videoRef.current.play(); // Replay the video
      setIsPlaying(true); // Ensure the play button state reflects this
    }
  };

  const buttonStyle = (buttonId) => ({
    padding: "5px 5px",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    backgroundColor:
      hoveredButton === buttonId
        ? "rgba(255, 255, 255, 0.7)"
        : "rgba(0, 0, 0, 0.7)",
    color: hoveredButton === buttonId ? "black" : "white",
    cursor: "pointer",
  });

  const videoContainerStyle = (item) => {
    let baseStyles = {
      position: "absolute",
      width:
        previewData?.backgroundWidth != undefined &&
          previewData?.backgroundWidth != "px"
          ? `${previewData?.backgroundWidth}`
          : "200px",
      height:
        previewData?.backgroundHeight != undefined &&
          previewData?.backgroundHeight != "px"
          ? `${previewData?.backgroundHeight}`
          : "200px",
    };
    let margin = {
      marginTop: item?.marginTop ? `${item?.marginTop}px` : "0px",
      marginRight: item?.marginRight ? `${item?.marginRight}px` : "0px",
      marginBottom: item?.marginBottom ? `${item?.marginBottom}px` : "0px",
      marginLeft: item?.marginLeft ? `${item?.marginLeft}px` : "0px",
    };
    switch (item?.align) {
      case "top left": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
          ...margin,
        };
      }
      case "top center": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
          ...margin,
        };
      }
      case "top right": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: 0,
          right: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`,
          ...margin,
        };
      }
      case "middle left": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
          ...margin,
        };
      }
      case "middle center": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
          ...margin,
        };
      }
      case "middle right": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`,
          ...margin,
        };
      }
      case "bottom left": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`,
          ...margin,
        };
      }
      case "bottom center": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`,
          ...margin,
        };
      }
      case "bottom right": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginRight: `${adjustedMarginRight}px`,
          ...margin,
        };
      }
      default:
        return { ...baseStyles, ...margin };
    }
  };

  return (
    <div
      className="backgroundHolder"
      style={{
        "overflow-y": "scroll",
        "scrollbar-width": "none",
        "-ms-overflow-style": "none",
        height: "100%",
        width: "100%",
        position: "absolute",
        "border-radius": "0 0 31px 31px",
      }}
    >
      <div style={{ ...videoContainerStyle(previewData) }}>

        <video
          ref={videoRef}
          key={previewData.videoData}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: 0,
          }}
          autoPlay
          muted={muted}
          playsInline
          onEnded={handleVideoEnd} // Handle video repeat manually
        >
          <source src={previewData.videoData} type="video/mp4" />
          <source src={previewData.videoData} type="video/ogg" />
          <source src={previewData.videoData} type="video/webm" />
          Your browser does not support the video tag.
        </video>
        <div
          style={{
            zIndex: "30",
            position: "absolute",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            gap: "10px",
          }}
        >
          <button
            style={buttonStyle("play-pause")}
            id="play-pause-button"
            onMouseEnter={() => setHoveredButton("play-pause")}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={handlePlayPause} // Toggle play/pause
          >
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </button>
          <button
            style={buttonStyle("reset")}
            id="reset-button"
            onMouseEnter={() => setHoveredButton("reset")}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={handleReset}
          >
            <RestartAltIcon />
          </button>
          <button
            style={buttonStyle("mute")}
            id="mute-button"
            onMouseEnter={() => setHoveredButton("mute")}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={handleMute}
          >
            {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
          </button>
        </div>
      </div>
      {children}
    </div>
  );
};

export default VideoBackground;
