import React from "react";
import tinycolor from "tinycolor2";
import DOMPurify from 'dompurify';
const Header =({ previewData }) => { 
  let headerDepth = previewData?.headerDepth ? previewData?.headerDepth : 0;
  let depthInPx = (600 * headerDepth) / 100;
  let alpha = previewData?.opacity ? previewData?.opacity : 1;
  const rgbaColor = previewData?.background
    ? tinycolor(previewData?.background).setAlpha(alpha).toRgbString()
    : "";
  
  const getCommonPositionStyle = (item) => {
    let baseStyles = {
      position: item?.headerIconAlign ? "absolute" : "relative",
    };

    switch (item.headerIconAlign) {
      case "top left": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "top center": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "top right": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: 0,
          right: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`,
        };
      }
      case "middle left": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "middle center": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "middle right": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`,
        };
      }
      case "bottom left": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "bottom center": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "bottom right": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginRight: `${adjustedMarginRight}px`,
        };
      }
      default:
        return baseStyles;
    }
  };
  const getHeaderContainerStyle = (item) => { 
    return {
      color: item?.fontColor,
      textAlign: item?.headerTextAlign,
      fontSize: item.height || "12px",
      height: item?.headerDepth ? `${depthInPx}px` : "25px",
      border: item?.borderColor ? `1px solid ${item?.borderColor}` : "",
      background: rgbaColor ? rgbaColor : item?.background,
      paddingTop: item?.paddingTop ? `${item?.paddingTop}px` : "0px",
      paddingRight: item?.paddingRight ? `${item?.paddingRight}px` : "0px",
      paddingBottom: item?.paddingBottom ? `${item?.paddingBottom}px` : "0px",
      paddingLeft: item?.paddingLeft ? `${item?.paddingLeft}px` : "0px",

      marginTop: item?.marginTop ? `${item?.marginTop}px` : "0px",
      marginRight: item?.marginRight ? `${item?.marginRight}px` : "0px",
      marginBottom: item?.marginBottom ? `${item?.marginBottom}px` : "0px",
      marginLeft: item?.marginLeft ? `${item?.marginLeft}px` : "0px", 
      display: item?.headerIcon && "flex",
      justifyContent: item?.headerIcon && "space-between",
      position: "relative",
    };
  };
  const getHeaderTextStyle = (item) => {
    return {
      "--customeFont": previewData?.headerFont,
      width: item?.width || "100%",
      color: item?.fontColor,
      fontFamily: item?.headerFont,
      textAlign: item?.headerTextAlign,
      position: item?.xTextPosition ? "absolute" : "relative",
      top: item?.xTextPosition ? item.xTextPosition : "10",
      [item?.headerTextAlign || "left"]: item?.yTextPosition
        ? item.yTextPosition
        : "0px",
      fontSize: item?.headerTextSize || "12px",
    };
  };
  const getHeaderIconContainerStyle = (item) => {
    return {
      zIndex: 1,
      display: "flex",
      marginTop: item?.iconMarginTop ? `${item?.iconMarginTop}px` : "0px",
      marginRight: item?.iconMarginRight ? `${item?.iconMarginRight}px` : "0px",
      marginBottom: item?.iconMarginBottom
        ? `${item?.iconMarginBottom}px`
        : "0px",
      marginLeft: item?.iconMarginLeft ? `${item?.iconMarginLeft}px` : "0px",
      paddingTop: item?.iconPaddingTop ? `${item?.iconPaddingTop}px` : "0px",
      paddingRight: item?.iconPaddingRight
        ? `${item?.iconPaddingRight}px`
        : "0px",
      paddingBottom: item?.iconPaddingBottom
        ? `${item?.iconPaddingBottom}px`
        : "0px",
      paddingLeft: item?.iconPaddingLeft ? `${item?.iconPaddingLeft}px` : "0px",
    };
  };
  const getHeaderIconStyle = (item) => {
    return {
      width: item?.iconWidth ? `${item?.iconWidth}px` : "50px",
      height: item?.iconHeight ? `${item?.iconHeight}px` : "20px",
    };
  };
  
  return (
    <div style={{ ...getHeaderContainerStyle(previewData) }}>
      {previewData?.headerText && (
        <h2 
          dangerouslySetInnerHTML={{
            __html:DOMPurify.sanitize(previewData.headerText)
          }}
          className="editor-inner-text custom-font"
          style={{...getHeaderTextStyle(previewData)
          }}
        ></h2>
      )}

      {previewData?.headerIcon && (
        <div style={{ ...getCommonPositionStyle(previewData),...getHeaderIconContainerStyle(previewData) }}>
          <img
            alt="Header Icon"
            src={previewData?.headerIcon}
            style={{ ...getHeaderIconStyle(previewData) }}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
