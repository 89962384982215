import React, { useEffect } from 'react'
import '../index.css'
import { useDispatch, useSelector } from 'react-redux'
import { userBookedPharmacyAppointment } from '../../redux/store/appointment/appointmentSelector'
import { getUserPharmacyAppot } from '../../redux/store/appointment/getAppointment';
import moment from 'moment'
import Logo from "../../assets/images/logo.png";
import { useLocation } from 'react-router-dom';

const ApptSummary = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const bookedAppointment = location.state?.message;
    return (
        <div className='container-fluid g-0'>
            <div className='card card-custom card-help'>
                <div className='card-header bg-inkytext-white text-center'>
                    <a href="https://elxoinc.com" target="_blank" rel="noopener noreferrer">
                        <img className="logo w-15" src={Logo} alt="Elxo Inc" />
                    </a>
                </div>
                <div className='card-body card-body-h-vh bg-card p-0 container-340 '>
                    <div className='help-card-bottom help-card-2'>
                        <div className='bg-white text-inky card-help-body opicity-5'>
                            <p className="mb-0 normal_text appt_thanks">Your appointment is booked for </p>
                            <p className="fw-semibold  mt-3 fs-5 pb-0 mb-0">{bookedAppointment}</p>
                            {/* {bookedAppointment && bookedAppointment.date && <p className="fw-semibold  mt-3 fs-5 pb-0 mb-0">{moment(bookedAppointment.date).format('dddd Do MMMM')} <br /> at {moment(bookedAppointment.date).format('LT')}</p>} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApptSummary