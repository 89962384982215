const Navigation = ({ children, previewData, setPreviewData }) => {
  let navigationData = previewData[previewData.currentPage]?.find((item) => item.type === "Navigation")?.values[0];
  const getNavigationWrapperStyles = (item, position, alignValue) => {
    const baseStyles = {
      position: position ? position : "relative",
      zIndex: 999,
      width: "100%",
    };

    let marginTop = item?.marginTop ? item?.marginTop : 0;
    let marginBottom = item?.marginBottom ? item?.marginBottom : 0;
    let marginLeft = item?.marginLeft ? item?.marginLeft : 0;
    let marginRight = item?.marginRight ? item?.marginRight : 0;

    let topMargin = marginTop - marginBottom;
    let leftMargin = marginLeft - marginRight;
    let bottomMargin = marginBottom - marginTop;

    let paddings = {
      paddingTop: item?.paddingTop ? `${item?.paddingTop}px` : "0px",
      paddingRight: item?.paddingRight ? `${item?.paddingRight}px` : "0px",
      paddingBottom: item?.paddingBottom ? `${item?.paddingBottom}px` : "0px",
      paddingLeft: item?.paddingLeft ? `${item?.paddingLeft}px` : "0px",
    };

    if (alignValue?.includes("middle")) {
      return {
        ...baseStyles,
        ...paddings,
        top: item?.marginBottom ? `calc(50% - ${item?.marginBottom}px)` : "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        marginTop: item?.marginTop ? `${item?.marginTop}px` : "0px",
        marginLeft: leftMargin,
      };
    } else if (alignValue?.includes("top")) {
      return {
        ...baseStyles,
        ...paddings,
        top: item?.marginBottom ? `-${item?.marginBottom}px` : 0,
        left: 0,
        marginTop: item?.marginTop ? `${item?.marginTop}px` : "0px",
        marginLeft: leftMargin,
      };
    } else if (alignValue?.includes("bottom")) {
      return {
        ...baseStyles,
        ...paddings,
        bottom: item?.marginTop ? `-${item?.marginTop}px` : 0,
        left: 0,
        marginBottom: item?.marginBottom ? `${item?.marginBottom}px` : "0px",
        marginLeft: leftMargin,
      };
    } else {
      return {
        ...baseStyles,
        ...paddings,
        bottom: item?.marginTop ? `-${item?.marginTop}px` : 0,
        left: 0,
        marginBottom: item?.marginBottom ? `${item?.marginBottom}px` : "0px",
        marginLeft: leftMargin,
      };
    }
  };

  const handleSelectNextPrevPage = (page, data) => {
    if (!previewData || !previewData.currentPage || !previewData.pageInfo?.totalPages) return;

    let findObj = data?.find((elem) => elem.type === "Page");
    let nextPageNo = findObj?.values?.[0]?.nextPage;
    let prevPageNo = findObj?.values?.[0]?.previousPage;

    let tempPreviewData = { ...previewData };
    let currentPage = tempPreviewData.currentPage;

    if (typeof currentPage === "string" && currentPage.startsWith("page")) {
      currentPage = Number(currentPage.replace("page", ""));
    } else {
      currentPage = Number(currentPage) || 1; // Default to page 1 if parsing fails
    }

    if (page === "prev") {
      currentPage = prevPageNo ?? currentPage - 1;
      currentPage = Math.max(currentPage, 1); // Prevent going below page 1
    } else {
      currentPage = nextPageNo ?? currentPage + 1;
      let totalPages = Number(previewData.pageInfo.totalPages) || 1;
      currentPage = Math.min(currentPage, totalPages); // Prevent exceeding totalPages
    }
    setPreviewData({ ...previewData, currentPage: `page${currentPage}` });
  };



  const getNavigationItemStyle = (item) => {
    let textAlign = "left";

    if (item?.align?.includes("left")) {
      textAlign = "left";
    } else if (item?.align?.includes("center")) {
      textAlign = "center";
    } else if (item?.align?.includes("right")) {
      textAlign = "right";
    } else if (item?.direction) {
      if (item?.direction === "left") {
        textAlign = "left";
      } else {
        textAlign = "right";
      }
    }

    return { textAlign: textAlign };
  };

  return (
    <>
      {navigationData?.direction === "both" ? (
        <div
          style={getNavigationWrapperStyles(
            navigationData,
            "",
            navigationData?.alignByScreen ?? "top"
          )}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {navigationData?.imageLeft ? (
                <div>
                  <img
                    onClick={() =>
                      handleSelectNextPrevPage(
                        "prev",
                        previewData[
                        previewData?.currentPage
                        ]
                      )
                    }
                    src={navigationData?.imageLeft}
                    alt="Left"
                    style={{
                      height:
                        navigationData?.leftNavigationSize
                          ? `${navigationData?.leftNavigationSize}px`
                          : "20px",
                      width:
                        navigationData?.leftNavigationSize
                          ? `${navigationData?.leftNavigationSize}px`
                          : "20px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : (
                <div>
                  <p
                    onClick={() =>
                      handleSelectNextPrevPage(
                        "prev",
                        previewData[
                        previewData?.currentPage
                        ]
                      )
                    }
                    className="custom-font"
                    style={{
                      "--customeFont": navigationData?.navigationFont,
                      fontSize:
                        navigationData?.leftNavigationSize
                          ? `${navigationData?.leftNavigationSize}px`
                          : "15px",
                      fontWeight: 600,
                      color: navigationData?.fontColor,
                      cursor: "pointer",
                    }}
                  >
                    {navigationData?.leftNavigationText}
                  </p>
                </div>
              )}
            </div>

            <div>
              {navigationData?.imageRight ? (
                <div>
                  <img
                    onClick={() =>
                      handleSelectNextPrevPage(
                        "next",
                        previewData[
                        previewData?.currentPage
                        ]
                      )
                    }
                    src={navigationData?.imageRight}
                    alt="Right"
                    style={{
                      height:
                        navigationData?.rightNavigationSize
                          ? `${navigationData?.rightNavigationSize}px`
                          : "20px",
                      width:
                        navigationData?.rightNavigationSize
                          ? `${navigationData?.rightNavigationSize}px`
                          : "20px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : (
                <div>
                  <p
                    onClick={() =>
                      handleSelectNextPrevPage(
                        "next",
                        previewData[
                        previewData?.currentPage
                        ]
                      )
                    }
                    className="custom-font"
                    style={{
                      "--customeFont": navigationData?.navigationFont,
                      fontSize:
                        navigationData?.rightNavigationSize
                          ? `${navigationData?.rightNavigationSize}px`
                          : "15px",
                      fontWeight: 600,
                      color: navigationData?.fontColor,
                      cursor: "pointer",
                    }}
                  >
                    {navigationData?.rightNavigationText}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={getNavigationWrapperStyles(
            navigationData,
            "",
            navigationData?.alignByScreen ?? "top"
          )}
        >
          {navigationData?.direction === "left" && (
            <div
              style={getNavigationItemStyle(navigationData)}
            >
              {navigationData?.imageLeft ? (
                <div>
                  <img
                    onClick={() =>
                      handleSelectNextPrevPage(
                        "prev",
                        previewData[
                        previewData?.currentPage
                        ]
                      )
                    }
                    src={navigationData?.imageLeft}
                    alt="Left"
                    style={{
                      height:
                        navigationData?.leftNavigationSize
                          ? `${navigationData?.leftNavigationSize}px`
                          : "20px",
                      width:
                        navigationData?.leftNavigationSize
                          ? `${navigationData?.leftNavigationSize}px`
                          : "20px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : (
                <div>
                  <p
                    onClick={() =>
                      handleSelectNextPrevPage(
                        "prev",
                        previewData[
                        previewData?.currentPage
                        ]
                      )
                    }
                    style={{
                      fontSize:
                        navigationData?.leftNavigationSize
                          ? `${navigationData?.leftNavigationSize}px`
                          : "15px",
                      fontWeight: 600,
                      color: navigationData?.fontColor,
                      cursor: "pointer",
                    }}
                  >
                    {navigationData?.leftNavigationText}
                  </p>
                </div>
              )}
            </div>
          )}

          {navigationData?.direction === "right" && (
            <div
              style={getNavigationItemStyle(navigationData)}
            >
              {navigationData?.imageRight ? (
                <div>
                  <img
                    onClick={() =>
                      handleSelectNextPrevPage(
                        "next",
                        previewData[
                        previewData?.currentPage
                        ]
                      )
                    }
                    src={navigationData?.imageRight}
                    alt="Right"
                    style={{
                      height:
                        navigationData?.rightNavigationSize
                          ? `${navigationData?.rightNavigationSize}px`
                          : "20px",
                      width:
                        navigationData?.rightNavigationSize
                          ? `${navigationData?.rightNavigationSize}px`
                          : "20px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : (
                <div>
                  <p
                    onClick={() =>
                      handleSelectNextPrevPage(
                        "next",
                        previewData[
                        previewData?.currentPage
                        ]
                      )
                    }
                    style={{
                      fontSize:
                        navigationData?.rightNavigationSize
                          ? `${navigationData?.rightNavigationSize}px`
                          : "15px",
                      fontWeight: 600,
                      color: navigationData?.fontColor,
                      cursor: "pointer",
                    }}
                  >
                    {navigationData?.rightNavigationText}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Navigation;