const ColorBackground = ({ children, previewData }) => {
  const getStyle = (item) => { 
    let baseStyles = {
      position: "absolute",
      background: `${previewData.background}`,
      zIndex: "0",
      width: (previewData?.backgroundWidth != undefined && previewData?.backgroundWidth != "px")
        ? `${previewData?.backgroundWidth}`
        : "50px",
      height: (previewData?.backgroundHeight != undefined && previewData?.backgroundHeight != "px")
        ? `${previewData?.backgroundHeight}`
        : "20px", 
    };
    let margin= {
      marginTop: item?.marginTop ? `${item?.marginTop}px` : "0px",
      marginRight: item?.marginRight ? `${item?.marginRight}px` : "0px",
      marginBottom: item?.marginBottom ? `${item?.marginBottom}px` : "0px",
      marginLeft: item?.marginLeft ? `${item?.marginLeft}px` : "0px",
    }
    switch (item?.align) {
      case "top left": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
          ...margin
        };
      }
      case "top center": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
          ...margin
        };
      }
      case "top right": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: 0,
          right: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`,
          ...margin
        };
      }
      case "middle left": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
          ...margin
        };
      }
      case "middle center": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
          ...margin
        };
      }
      case "middle right": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`,
          ...margin
        };
      }
      case "bottom left": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`,
          ...margin
        };
      }
      case "bottom center": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`,
          ...margin
        };
      }
      case "bottom right": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginRight: `${adjustedMarginRight}px`,
          ...margin
        };
      }
      default:
        return {...baseStyles,...margin};
    }
  };
  return (
    <div
      className="backgroundHolder"
      style={{
        "overflow-y": "scroll",
        "scrollbar-width": "none",
        "-ms-overflow-style": "none",
        position: "absolute",
        height: "100%",
        width: "100%",
        "border-radius": "0 0 31px 31px",
      }}
    >
      <div 
        style={{ ...getStyle(previewData) }}
      ></div>
      {children}
    </div>
  );
  };

  export default ColorBackground;