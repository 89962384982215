import React, { useEffect, useState } from "react";
import "./Appointment.css";
import moment from "moment";
import axios from "axios";
import ReactDatePicker from "react-datepicker";
import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { decodeToken } from "../../../utils";
import CircularProgress from "@mui/material/CircularProgress";
import AlarmDialog from "../AlarmDialog.js";
import { useNavigate } from 'react-router-dom';

const Appointment = ({ previewData }) => {
  const navigate = useNavigate();
  const schedulerId = previewData?.appointment?.id;
  const companyId = previewData?.companyId;
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    type: "", // 'success' or 'error'
    title: "",
    description: "",
  });
  // ✅ Local State (Replaces Redux State)
  const [schedulerDetails, setSchedulerDetails] = useState({});
  const [appointments, setAppointments] = useState([]);
  const [schedulerAppointmentsList, setSchedulerAppointmentsList] = useState(
    {}
  );
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [isSubmitLoader, setIsSubmitLoader] = useState(false);
  const [apptSelected, setApptSelected] = useState(false);
  const [apptSelectedRow, setApptSelectedRow] = useState("");
  const [bookedDate, setBookedDate] = useState("");
  const [date, setDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [slot, setSlot] = useState();
  const [day, setDay] = useState();
  const [minDate, setMinDate] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedLocationName, setSelectedLocationName] = useState("");
  const [selectedDateValue, setSelectedDateValue] = useState(null);
  const [sellectedAppointment, setSellectedAppointment] = useState(null);
  const [locations, setLocations] = useState([]);

  // ✅ Fetch Scheduler Details
  useEffect(() => {
    if (!schedulerId) return;

    const fetchSchedulerDetails = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_ENLIGHTEN_API_URL}/api/Scheduler/GetScheduler?schedulerId=${schedulerId}&companyId=${companyId}`
        );
        if (res.status === 200) {
          setSchedulerDetails(res.data.value);
        }
      } catch (error) {
        console.error("Error fetching scheduler details:", error);
      }
    };

    fetchSchedulerDetails();
  }, [schedulerId]);

  // ✅ Fetch Appointments & Scheduler Appointments
  useEffect(() => {
    if (!schedulerId) return;

    const fetchAppointments = async () => {
      try {
        setIsShowLoader(true);
        const res = await axios.get(
          `${process.env.REACT_APP_ENLIGHTEN_API_URL}/api/Appointment/GetAllAppointments?schedulerId=${schedulerId}`
        );
        setAppointments(res.data.value);
        setIsShowLoader(false);
      } catch (error) {
        setIsShowLoader(false);
        console.error("Error fetching appointments:", error);
      }
    };

    const fetchSchedulerAppointments = async () => {
      try {
        setIsShowLoader(true);
        const res = await axios.get(
          `${process.env.REACT_APP_ENLIGHTEN_API_URL}/api/Scheduler/GetScheduler?schedulerId=${schedulerId}&companyId=${companyId}`
        );
        setSchedulerAppointmentsList(res.data.value);
        setIsShowLoader(false);
      } catch (error) {
        setIsShowLoader(false);
        console.error("Error fetching scheduler appointments:", error);
      }
    };

    fetchAppointments();
    fetchSchedulerAppointments();
  }, [schedulerId]);

  // ✅ Organize Appointments & Set Date Ranges
  useEffect(() => {
    if (
      schedulerId &&
      appointments &&
      appointments != "error" &&
      appointments.length > 0
    ) {
      const locations = [
        ...new Map(
          appointments.map(({ locationId, locationName }) => [
            locationId,
            { locationId, locationName },
          ])
        ).values(),
      ];
      setLocations(locations);
      if (selectedLocationId === "") {
        setSelectedLocationId(locations[0].locationId);
        setSelectedLocationName(locations[0].locationName);
      }

      (async () => {
        const organizedTimestamps = await processAppointments(appointments);
        const dates = Object.keys(organizedTimestamps);
        setSlot(organizedTimestamps);

        var date = dates[dates.length - 1];
        let endDate = new Date(
          date.toString().replace("-", "/").replace(/-/g, "/")
        );

        var bookingDelay = schedulerAppointmentsList?.bookingDelay ?? 0;
        const currDate = new Date();
        currDate.setDate(currDate?.getDate() + bookingDelay);

        let nextApptDate = new Date(currDate);
        let nextApointmentDay = nextApptDate?.getDay();
        if (schedulerAppointmentsList !== undefined)
          if (schedulerAppointmentsList !== null)
            if (!Array.isArray(schedulerAppointmentsList))
              if ("bookingDelay" in schedulerAppointmentsList) {
                while (
                  !schedulerAppointmentsList?.weekDayList?.includes(
                    nextApointmentDay
                  )
                ) {
                  nextApptDate?.setDate(nextApptDate?.getDate() + 1);
                  nextApointmentDay = nextApptDate?.getDay();
                }
                setDate(nextApptDate);
                setMinDate(currDate);
                setEndDate(endDate);
              }
      })();
    }
  }, [schedulerId, appointments, schedulerAppointmentsList]);

  useEffect(() => {
    if (slot && date) {
      setDay(slot[moment(date).format("YYYY-MM-DD")]);
      setSelectedDateValue(date);
    }
  }, [date]);

  const processAppointments = async (appointments, bookedAppointmentsList) => {
    const organizedTimestamps = {};
    for (const appointment of appointments) {
      const date = appointment.date.split("T")[0];
      if (!organizedTimestamps[date]) {
        organizedTimestamps[date] = [];
      }
      organizedTimestamps[date].push(appointment);
    }
    return organizedTimestamps;
  };

  const isWeekday = (date) => {
    const day = date?.getDay();
    return schedulerAppointmentsList?.weekDayList?.includes(day);
  };

  const handleChange = (date) => {
    setApptSelectedRow("");
    if (!date) return;
    setSellectedAppointment(null);
    setDay(slot[moment(date).format("YYYY-MM-DD")]);
    setSelectedDateValue(date);
  };

  const handleLocationChange = (e) => {
    setSelectedLocationId(e.target.value);
    setSelectedLocationName(e.target.name);
    setSellectedAppointment(null);
    setApptSelectedRow("");
  };

  const handleBook = async () => {
    setIsSubmitLoader(true);
    let bearerToken = localStorage.getItem("token");
    var user = decodeToken(bearerToken);
    const config = {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    };
    await axios
      .post(
        `${process.env.REACT_APP_ENLIGHTEN_API_URL}/api/CampaignTemplatePreview/BookAppointment`,
        {
          userId: user.UserId,
          schedulerId: sellectedAppointment.schedulerId,
          appointmentId: sellectedAppointment.id,
        },
        config
      )
      .then((response) => {
        setIsSubmitLoader(false);
        if (response.data.value) {
          // Show success dialog
          setBookedDate(sellectedAppointment.id)
          const updatedDay = day.map(item =>
            item.id === sellectedAppointment.id
              ? { ...item, is_appt_booked: true }
              : item
          );
          setDay(updatedDay);
          navigate('/appt-success', { state: { message: response.data.message + " at " + selectedLocationName } });

          // setDialogState({
          //   isOpen: true,
          //   type: "success",
          //   title: "Appointment Booked",
          //   description: response.data.message,
          // });
        } else {
          // Show error dialog if response.value is false
          setDialogState({
            isOpen: true,
            type: "error",
            title: "Booking Failed",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        setIsSubmitLoader(false);
        console.log(error)
        setDialogState({
          isOpen: true,
          type: "error",
          title: "Error",
          description: "An error occurred while booking the appointment.",
        });
      });

  };
  return (
    <div
      className={`preview_container ${isShowLoader ? "loading" : ""}`}
      style={{
        marginTop: previewData?.marginTop ? `${previewData?.marginTop}px` : "",
        marginLeft: previewData?.marginLeft
          ? `${previewData?.marginLeft}px`
          : "",
        marginRight: previewData?.marginRight
          ? `${previewData?.marginRight}px`
          : "",
        marginBottom: previewData?.marginBottom
          ? `${previewData?.marginBottom}px`
          : "",
        paddingTop: previewData?.paddingTop
          ? `${previewData?.paddingTop}px`
          : "",
        paddingRight: previewData?.paddingRight
          ? `${previewData?.paddingRight}px`
          : "",
        paddingLeft: previewData?.paddingLeft
          ? `${previewData?.paddingLeft}px`
          : "",
        paddingBottom: previewData?.paddingBottom
          ? `${previewData?.paddingBottom}px`
          : "",
      }}
    >
      {isShowLoader ? (
        <div className="loader">
          <i className="fa-solid fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div className="container-fluid g-0">
          <div className="pickatime">
            Pick a timeslot for us to call you.
          </div>
          <FormControl sx={{ width: "100%" }} className="custom_form_control">
            <Select
              className="input_blue_select"
              name="listType"
              onChange={(e) => handleLocationChange(e)}
              value={selectedLocationId || ""}
              displayEmpty={true}
              renderValue={(selected) => {
                return selected
                  ? locations.find((x) => x.locationId === selected)
                    ?.locationName
                  : "Select Location";
              }}
              sx={{ pr: 5 }}
              endAdornment={
                selectedLocationId && (
                  <InputAdornment className="text-blue" position="end">
                    <IconButton
                      className="text-blue"
                      edge="end"
                      onClick={() =>
                        handleLocationChange({ target: { value: "" } })
                      }
                    >
                      <ClearIcon className="text-blue" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            >
              {locations.map((x) => (
                <MenuItem
                  className="text-blue"
                  key={x.locationId}
                  value={x.locationId}
                >
                  <p style={{ color: "#284c78" }}>{x.locationName}</p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="card card-custom" style={{ backgroundColor: "unset" }}>
            <div className=" text-blue container-340 ">
              <div className="mb-2 cander_red mt-2">
                <div className="mb-2 calender-picker mt-2">
                  <div className="calendar normal_text">
                    <ReactDatePicker
                      selected={selectedDateValue}
                      onChange={(date) => {
                        handleChange(date[0]);
                      }}
                      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                      maxDate={endDate}
                      selectsRange
                      inline
                      minDate={minDate}
                      filterDate={isWeekday}
                    />
                  </div>
                </div>
              </div>
              {schedulerDetails?.allLocationReviewApptList?.length > 0 && (
                <div className="time-zone-container">
                  <div className="d-flex justify-content-between flex-wrap preview_time_cont">
                    {day &&
                      day?.length > 0 &&
                      day !== "error" &&
                      day?.map((data) => {
                        const time = moment(data?.date).format("h:mm A");
                        return (
                          data.locationId === selectedLocationId && (
                            <div className="time-zone-radio-group">
                              <input
                                type="radio"
                                style={{
                                  backgroundColor:
                                    data.is_appt_booked || !data.isAvailable || data.id === bookedDate
                                      ? "grey"
                                      : apptSelectedRow === data.id
                                        ? "#F0F7FA"
                                        : " ",
                                }}
                                className={
                                  data.is_appt_booked || !data.isAvailable || data.id === bookedDate
                                    ? "time-zone-radio not-allowed"
                                    : "time-zone-radio "
                                }
                                value={time}
                                name="timeZone"
                                id={data["id"]}
                                disabled={
                                  data.is_appt_booked || !data.isAvailable || data.id === bookedDate
                                    ? true
                                    : false
                                }
                              />
                              <label
                                disabled={
                                  data.is_appt_booked || !data.isAvailable || data.id === bookedDate
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  setSellectedAppointment(data);
                                  setApptSelectedRow(data.id);
                                }}
                                className={
                                  data.is_appt_booked || !data.isAvailable || data.id === bookedDate
                                    ? "time-zone-label normal_text1 not-allowed"
                                    : "time-zone-label normal_text1"
                                }
                                style={{
                                  pointerEvents:
                                    data.is_appt_booked || !data.isAvailable || data.id === bookedDate
                                      ? "none"
                                      : "auto",
                                  backgroundColor:
                                    apptSelectedRow === data.id
                                      ? "#003b71"
                                      : "",
                                  color:
                                    apptSelectedRow === data.id ? "white" : "",
                                  borderColor:
                                    apptSelectedRow === data.id
                                      ? "#003b71"
                                      : "",
                                  borderWidth:
                                    apptSelectedRow === data.id ? 2 : "",
                                }}
                              >
                                {time}
                              </label>
                            </div>
                          )
                        );
                      })}
                  </div>
                </div>
              )}
              {
                <>
                  <div className="mt-4 text-center m-auto">
                    <button
                      className={
                        "btn btn-outline-primary curved-button normal_text p-0 w-50 w-lg-20"
                      }
                      style={{
                        pointerEvents:
                          sellectedAppointment === null ? "none" : "auto",
                        backgroundColor: apptSelected ? "#F0F7FA" : "",
                        borderColor: apptSelected ? "#003b71" : "",
                        borderWidth: apptSelected ? 2 : "",
                        fontWeight: apptSelected ? "bold" : "",
                      }}
                      onClick={handleBook}
                    >
                      {isSubmitLoader ? (
                        <CircularProgress
                          sx={{ color: "rgb(0, 59, 113)", marginTop: "3px" }}
                          color="secondary"
                          size={20}
                        />
                      ) : (
                        <p style={{ color: "rgb(0, 59, 113)" }}>Book Appointment</p>
                      )}
                    </button>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      )}
      <AlarmDialog
        isOpen={dialogState.isOpen}
        onClose={() => {
          setDialogState((prevState) => ({
            ...prevState,
            isOpen: false,
          }));
        }}
        type={dialogState.type}
        title={dialogState.title}
        description={dialogState.description}
      />
    </div>
  );
};

export default Appointment;
