import React, { useState, useRef, useEffect } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

const VideoBackground = ({ children, previewData }) => {
  const [hoveredButton, setHoveredButton] = useState(null);

  const [muted, setMuted] = useState(false); 
  const [isPlaying, setIsPlaying] = useState(true); // Default state: video is playing
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play(); // Ensure video plays by default
    }
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      const isMute=(previewData?.muteOnOpen === undefined)?false:previewData?.muteOnOpen;
      setMuted(isMute);
    }
  }, [previewData.muteOnOpen]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play(); // Ensure video plays by default
    }
  }, [previewData?.muteOnOpen]);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying); // Toggle the play/pause state
    }
  };

  const handleReset = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
      videoRef.current.play();
      setIsPlaying(true); // Ensure play state is true after reset
    }
  };

  const handleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !muted;
      setMuted(!muted);
    }
  };

  const handleVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Reset the video to the beginning
      videoRef.current.play(); // Replay the video
      setIsPlaying(true); // Ensure the play button state reflects this
    }
  };

  const buttonStyle = (buttonId) => ({
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    backgroundColor:
      hoveredButton === buttonId
        ? "rgba(255, 255, 255, 0.7)"
        : "rgba(0, 0, 0, 0.7)",
    color: hoveredButton === buttonId ? "black" : "white",
    cursor: "pointer",
  });

  return (
    <div
      className="backgroundHolder"
      style={{
        overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        height: "100%",
        width: "100%",
        borderRadius: "0 0 31px 31px",
      }}
    >
      <video
        ref={videoRef}
        key={previewData.videoData}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 0,
        }}
        autoPlay
        muted={muted}
        playsInline
        onEnded={handleVideoEnd} // Handle video repeat manually
      >
        <source src={previewData.videoData} type="video/mp4" />
        <source src={previewData.videoData} type="video/ogg" />
        <source src={previewData.videoData} type="video/webm" />
        Your browser does not support the video tag.
      </video>
      <div
        style={{
          zIndex: "30",
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          gap: "10px",
        }}
      >
        <button
          style={buttonStyle("play-pause")}
          id="play-pause-button"
          onMouseEnter={() => setHoveredButton("play-pause")}
          onMouseLeave={() => setHoveredButton(null)}
          onClick={handlePlayPause} // Toggle play/pause
        >
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </button>
        <button
          style={buttonStyle("reset")}
          id="reset-button"
          onMouseEnter={() => setHoveredButton("reset")}
          onMouseLeave={() => setHoveredButton(null)}
          onClick={handleReset}
        >
          <RestartAltIcon />
        </button>
        <button
          style={buttonStyle("mute")}
          id="mute-button"
          onMouseEnter={() => setHoveredButton("mute")}
          onMouseLeave={() => setHoveredButton(null)}
          onClick={handleMute}
        >
          {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </button>
      </div>

      {children}
    </div>
  );
};

export default VideoBackground;