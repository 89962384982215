import tinycolor from "tinycolor2";
import DOMPurify from "dompurify";
const Text = ({ previewData }) => {
  let alpha = previewData?.textOpacity ? previewData?.textOpacity : 1;
  const rgbaColor = previewData?.textBackground
    ? tinycolor(previewData?.textBackground).setAlpha(alpha).toRgbString()
    : "";
  const getCommonPositionStyle = (item) => {
    let baseStyles = {
      background: rgbaColor ? rgbaColor : previewData?.textBackground,
      position: item?.headerIconAlign ? "absolute" : "relative",
      borderRadius: previewData?.textBorderRadius
        ? `${previewData.textBorderRadius}px`
        : "",
      border: `1px solid ${previewData?.textBorder}`,
      height: previewData.textHeight,

      wordWrap: "break-word",
      marginTop: item?.marginTop ? `${item?.marginTop}px` : "0px",
      marginRight: item?.marginRight ? `${item?.marginRight}px` : "0px",
      marginBottom: item?.marginBottom ? `${item?.marginBottom}px` : "0px",
      marginLeft: item?.marginLeft ? `${item?.marginLeft}px` : "0px",
      paddingTop: item?.paddingTop ? `${item?.paddingTop}px` : "0px",
      paddingRight: item?.paddingRight ? `${item?.paddingRight}px` : "0px",
      paddingBottom: item?.paddingBottom ? `${item?.paddingBottom}px` : "0px",
      paddingLeft: item?.paddingLeft ? `${item?.paddingLeft}px` : "0px",
    };

    switch (item.headerIconAlign) {
      case "top left": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "top center": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "top right": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: 0,
          right: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`,
        };
      }
      case "middle left": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "middle center": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "middle right": {
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`,
        };
      }
      case "bottom left": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "bottom center": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`,
        };
      }
      case "bottom right": {
        const marginBottom = item.bottomMargin ? item.bottomMargin : 0;
        const marginTop = item.topMargin ? item.topMargin : 0;
        const marginRight = item.rightMargin ? item.rightMargin : 0;
        const marginLeft = item.leftMargin ? item.leftMargin : 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginRight: `${adjustedMarginRight}px`,
        };
      }
      default:
        return baseStyles;
    }
  };
  console.log("previewData", previewData);
  return (
    <div style={{ ...getCommonPositionStyle(previewData) }}>
      <div
        id="txt_editor"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(previewData.bodyText),
        }}
        className="editor-inner-text custom-font"
        style={{"--customeFont": previewData?.textFont}}
      />
    </div>
  );
};
export default Text;
