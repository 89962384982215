import React, { useEffect } from 'react'
import { selectFitResponse, selectReminderContent } from "../../redux/store/fit/fitSelector";
import {
    getFitContentAction
  } from "../../redux/store/fit/fitAction";
  import {
    setError,
    setLoader,
    setTokenError,
  } from "../../redux/store/loader/loaderAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { decodeToken } from "../../utils";
import '../index.css'
const FitDynamicThanks = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const fitContent = useSelector(selectReminderContent);
    useEffect(() => {
        dispatch(setError(""));
        const token = localStorage.getItem("token");
        var data = decodeToken(token);
    
        if (!token) {
          dispatch(setError("token is missing. Please contact Admin"));
          dispatch(setLoader(false));
          return;
        } else {
          let language = data.Langwritten === "Spanish" || data.Langspoken === "Spanish" ? "Spanish" : "English";
          dispatch(getFitContentAction({language: language, campCode: data.campCode }))
        }
      }, []);

    const bindText = (textSequenceId) => {
        let item = fitContent?.find(
          (x) => x.textSequenceId === textSequenceId
        )?.text;
        return item;
      };
    return (
        <div className='container-fluid g-0'>
            <div className='card card-custom card-help'>
                <div className='card-header bg-inky text-white text-center'>
                    <img class=" w-15" src="../../../../Logo.svg" />
                </div>
                <div className='card-body card-body-h-vh bg-fitkit-card-sum p-0 container-340 '>
                    <div className='help-card-bottom help-card-2'>
                        <div className='card-thanks card-help-body'>
                            <p className="mb-0 lbl_medium lh-sm">{bindText(9)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FitDynamicThanks