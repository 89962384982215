import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography
} from "@mui/material";
import { CheckCircle, Error } from "@mui/icons-material";

const AlarmDialog = ({ isOpen, onClose, title, description, type }) => {
  const isSuccess = type === "success";

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alarm-dialog-title"
      className="billing-update-dialog"
      sx={{
        "& .MuiDialog-paper": {
          border: `2px solid ${isSuccess ? "#28a745" : "#dc3545"}`,
          borderRadius: "8px",
          margin: "20px",
          fontFamily: `"Bahnschrift", sans-serif`,
        },
      }}
      maxWidth="sm"
    >
      <DialogTitle
        id="alarm-dialog-title"
        sx={{
          color: isSuccess ? "#28a745" : "#dc3545",
          fontWeight: 600,
          fontSize: "20px",
          padding: "15px 12px 10px",
          fontFamily: `"Bahnschrift", sans-serif`,
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {isSuccess ? (
          <CheckCircle sx={{ fontSize: 28, color: "#28a745" }} />
        ) : (
          <Error sx={{ fontSize: 28, color: "#dc3545" }} />
        )}
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          fontWeight: 500,
          fontSize: "18px",
          fontFamily: `"Bahnschrift", sans-serif`,
        }}
      >
        <Typography>{description}</Typography>
      </DialogContent>
      <DialogActions sx={{ borderTop: "none", padding: "16px", justifyContent: "flex-end" }}>
        <Button
          onClick={onClose}
          sx={{
            fontSize: "16px",
            backgroundColor: isSuccess ? "#28a745" : "#dc3545",
            color: "#fff",
            width: "180px",
            marginLeft: "10px",
            fontFamily: `"Bahnschrift", sans-serif`,
            "&:hover": {
              backgroundColor: isSuccess ? "#218838" : "#c82333",
            },
          }}
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlarmDialog;
