import React, { useEffect, useState, useRef } from "react";
import "./../index.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectFitResponse, selectReminderContent } from "../../redux/store/fit/fitSelector";
import { addFitResp, getFitResponseAction, getFitContentAction } from "../../redux/store/fit/fitAction";
import { setError, setLoader, setTokenError } from "../../redux/store/loader/loaderAction";
import PlayIcon from '../../assets/images/playIcon.png'
import { decodeToken } from "../../utils";
import { Underline } from "react-feather";

const FitReminderSixMonth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const campVideoRef = useRef(null);
  const fitResponse = useSelector(selectFitResponse);
  const fitContent = useSelector(selectReminderContent);
  const queryParams = new URLSearchParams(location.search);
  const { company, campaignId } = useParams();
  useEffect(() => {
    dispatch(setError(""));
    const token = queryParams.get("token");
    var data = decodeToken(token);

    if (!token) {
      dispatch(setError("token is missing. Please contact Admin"));
      dispatch(setLoader(false));
      return;
    } else {
      localStorage.setItem("token", token);
      let language = data.Langwritten === "Spanish" || data.Langspoken === "Spanish" ? "Spanish" : "English";

      dispatch(getFitContentAction({language: language, campCode: data.campCode }))
      dispatch(getFitResponseAction({ id: data.uid, campCode: data.campCode }));
    }
  }, []);

  useEffect(() => {
    if (fitResponse) {
      dispatch(setLoader(false));
      if (fitResponse.responseDe === "Y") {
        navigate(`/${company}/${campaignId}/fit/thank`);
      } else if (
        fitResponse?.responseDe === null &&
        fitResponse.responseDe !== "N"
      ) {
        var watchVideo = (fitResponse?.watchVideo === null || fitResponse?.watchVideo !== "Y") ? "N" : "";
        handleSendFITKit("N", watchVideo);
      }
    }
  }, [fitResponse]);

  useEffect(() => {
    const handleModalClose = () => {
      campVideoRef.current.pause();
    }
    const modalElement = document.getElementById("staticBackdrop");
    if(modalElement){
      modalElement.addEventListener("hidden.bs.modal", handleModalClose);
    }
    return () => {
      if(modalElement){
        modalElement.removeEventListener('hidden.bs.modal', handleModalClose);
      }
    }
  },[]);

  const handleSendFITKit = (resDe, viewvideo) => {
    const token = queryParams.get("token");
    const user = decodeToken(token);
    const data = {
      userGuid: user.uid,
      campaignCode: user.scheduleCode,
      responseDe: resDe,
      watchVideo: viewvideo
    };
    dispatch(addFitResp(data));
  };

  const handlePlayPause = () => {
    if (fitResponse?.watchVideo === 'N') {
      let resDes = fitResponse.responseDe === "Y" ? "Y" : fitResponse.responseDe === "N" ? "N" : "";
      handleSendFITKit(resDes, "Y");
    }

    if (campVideoRef.current) {
      if (campVideoRef.current.paused) {
        let playPromise = campVideoRef.current.play();
        if (playPromise !== undefined) {
          playPromise.then(() => {
            campVideoRef.current.play();
          })
          .catch(error => {
            console.log(error)
          });
        }
        // campVideoRef.current.requestFullscreen();
      } else {
        campVideoRef.current.pause();
      }
    }
  }

  const bindText = (textSequenceId) => {
    let item = fitContent?.find(
      (x) => x.textSequenceId === textSequenceId
    )?.text;
    return item;
  };

  return (
    <div className="container-fluid g-0">
      <div className="card card-custom card-help">
        <div className="card-header bg-inky text-white text-center">
          <img className=" w-15" src="../../Logo.svg" />
        </div>
        <div className="card-body card-body-h-vh bg-fit-v2-reminder bg-fit-card-one p-0 container-340 ">
          <div className="mt-auto">
            <div className="mt-2 mb-2 p-2">
              <div className="d-flex" style={{ border: `1px solid #ccc`, padding: "5px", borderRadius: "6px" }}>
                <div style={{ width: "65%" }}>
                  <h4 className="mb-0 px-2" style={{ textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap", paddingRight: "1rem", color: "#0D1C3D", paddingBottom: "0.3rem", fontSize: "2.0rem" }}>{bindText(1)}</h4>
                  <p className="mb-2 text-left fitv2-videodes px-2">{bindText(2)}</p>
                  <img className="px-2" alt="img "
                    src="https://elxovoiceurl.blob.core.windows.net/images/b4c976a9-c9b1-48eb-a128-b0ed5107d73a.svg"
                    style={{ height: "auto", width: "5rem" }}
                  />
                </div>

                <div style={{ width: "35%", border: "1px solid #000", borderRadius: "5px", position: "relative", maxHeight: "200px" }}>
                  <img alt="img "
                    src="https://elxoscheduler.blob.core.windows.net/images/Video_thumbnail.jpg"
                    style={{ width: "100%", objectFit: "cover", objectPosition: "center", height: "100%" }}
                  />
                  <img alt="img" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                    onClick={() => handlePlayPause()}
                    src={PlayIcon} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "50px", height: "50px", zIndex: 999 }}
                  />
                </div>
              </div>

              <div className="modal fade custom_fade_model" id="staticBackdrop" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body p-0" id="modalId" style={{ height: "50vh", backgroundColor: "#000" }}>
                      <video ref={campVideoRef}
                        style={{ height: "100%", width: "100%", objectFit: "cover", objectPosition: "center" }}
                        className="video-element" controls>
                        <source src="https://elxoscheduler.blob.core.windows.net/elxovideos/RicksStory.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-thanks text-white card-help-body mb-1 p-3">
              <p className="mb-0 text-left lbl_medium">
              {bindText(3)} <span style={{textDecoration:"Underline"}}> {bindText(4)}</span>
              </p>
              <p className="text-left mb-0 mt-4 lbl_medium">
              {bindText(5)}
              </p>
              <img src="/FITKIT.svg" alt="FITKIT Logo" style={{ height: "60px", width: "100%" }} />
              <p className="ttext-left mb-0 lbl_medium">
              {bindText(6)}
              </p>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <div className="btn_global" style={{border:"1px solid #7188A6", cursor:"pointer"}} onClick={() => handleSendFITKit("Y", "")}>
                <span className="mb-0">
                {bindText(7)}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="">
                <p className="text-center mb-0 lbl_medium p-3 fit_question">{bindText(8)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FitReminderSixMonth;
